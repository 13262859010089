import React, { useState } from "react";
import { useEffect } from "react";

import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import LandingHeader from "../components/LandingHeader";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Top20px from "../screens/Top20px";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBroadcastTower } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from "@fortawesome/fontawesome-free"

import LiveTvIcon from '@mui/icons-material/LiveTv';
import YouTubeIcon from '@mui/icons-material/YouTube';


import { Container, Stack, useMediaQuery } from "@mui/material";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import axios from "axios";
import { useTheme } from '@mui/material/styles';


const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);



const ShubadinamTv = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));


    const [data, setData] = useState([]);
    const [liveLinks, setLiveLinks] = useState([]);

    useEffect(() => {

        const localId = localStorage.getItem("SD_Id") || localStorage.getItem("premiumVerified")

        if (localId) {
            const url = `https://backend.shubadinam.com/showHomamLive?localId=${localId}`;

            axios.get(url)
                .then((response) => {


                    const show_Live = response.data.homamBookedCustomers;
                    const liveLinkUpdates = response.data.liveLinkUpdate;


                    const seenHomamNames = new Set();
                    const deDuplicated = show_Live.filter(item => {
                        if (!seenHomamNames.has(item.homamName)) {
                            seenHomamNames.add(item.homamName);
                            return true;
                        }
                        return false;
                    });
                    setData(deDuplicated)


                    const links = liveLinkUpdates.reduce((acc, link) => {
                        acc[link.eventName] = link.youtubeLink;
                        return acc;
                    }, {});

                    setLiveLinks(links);

                })
                .catch((error) => {
                    // console.log(error);
                })

        }

    }, [])







    const extractSrcAndConvert = (iframeString) => {
        // Extract the src part
        const srcMatch = iframeString.match(/src="([^"]+)"/);

        if (!srcMatch) return null; // Return null if src is not found

        const src = srcMatch[1]; // Get the src value

        // Convert the embed link to a watch link
        const videoId = src.split('/embed/')[1].split('?')[0]; // Extract the video ID
        const watchLink = `https://www.youtube.com/watch?v=${videoId}`; // Convert to YouTube watch link
        window.open(watchLink || "", '_blank');
        // return watchLink;
    };

    // const youtubeWatchLink = extractSrcAndConvert(iframeHTML);



    return (
        <div className="sdinam-tv-overall">
            <HelmetProvider>
                <Helmet>
                    <title>Shubadinam Tv  </title>
                    <meta name="description" content="Shubadinam Tv  - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <LandingHeader />
            <Top20px />
            <div className="tv-container">
                <div className="tv-container-live">
                    <Stack direction="row" spacing={2}>
                        {
                            data.length !== 0 &&
                            <>
                                <h2>Shubadinam Live</h2>
                                <LiveTvIcon fontSize="large" color="error" />
                            </>
                        }
                    </Stack>
                    <Stack direction={{ sm: 'column', md: 'row' }} spacing={2}>
                        {/* {
                            data.length < 1 && <h3 style={{ textAlign: "center" }}>Today, there is no live events available.</h3>
                        } */}
                        {
                            data.map((homam, index) => (
                                <Box key={index} sx={{ bgcolor: 'background.paper', p: 2, borderRadius: 2 }}>
                                    <Card sx={{ width: 300, cursor: "pointer" }}
                                        onClick={() => {
                                            extractSrcAndConvert(liveLinks[homam.homamName])
                                            // window.open(youtubeWatchLink || "", '_blank');
                                        }}
                                    >
                                        <CardContent>
                                            <div
                                                dangerouslySetInnerHTML={{ __html: liveLinks[homam.homamName] }}
                                            />
                                        </CardContent>

                                        <CardActions>
                                            <Button className="tv-button" sx={{ textTransform: "none" }} variant="outline" size="small">Watch {homam.homamName} Live on Youtube
                                            </Button>
                                        </CardActions>
                                    </Card>
                                    {/* {homam.homamName === "Avahanti-Homam" && "6 am"}
                                    {homam.homamName === "Ganapathi-Homam" && "6 am"}
                                    {homam.homamName === "Surya-Namaskar" && "6 am"}
                                    {homam.homamName === "Navagraha" && "6 am"} */}
                                </Box>
                            ))
                        }


                    </Stack>

                </div>
                <br />


                {/* videos */}
                <div className="tv-container-demo">

                    <Stack direction="row" spacing={2}>
                        <h2>Top Latest</h2>
                        <YouTubeIcon fontSize="large" color="error" />
                    </Stack>
                    <br />
                    <Container sx={{ py: 4 }}>
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                                gridAutoRows: 'auto',
                                gap: { xs: 3, md: 4 }, // Responsive gap value
                                bgcolor: 'background.paper',
                                p: 3,
                                borderRadius: 2,
                                justifyContent: 'center',
                                alignItems: 'start',
                            }}
                        >
                            <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
                                <Box
                                    sx={{
                                        position: 'relative',
                                        paddingTop: '56.25%', // Maintain aspect ratio (16:9)
                                        overflow: 'hidden',
                                        borderRadius: '4px 4px 0 0',
                                    }}
                                >
                                    <iframe
                                        src="https://www.youtube.com/embed/x1YUd2Ane2s?si=fMvsYtOp64Sd2DZi"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin"
                                        allowFullScreen
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    />
                                </Box>
                                <CardActions sx={{ justifyContent: 'center', p: 2 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        href="https://www.youtube.com/watch?v=x1YUd2Ane2s"
                                        target="_blank"
                                        sx={{
                                            textTransform: 'none',
                                            ':hover': { bgcolor: '#1976d2', color: 'white' }, // Hover effect
                                        }}
                                    >
                                        Watch Video on Youtube
                                    </Button>
                                </CardActions>
                            </Card>

                            {
                                !isMobile &&

                                <Card sx={{ width: 300, boxShadow: 3, borderRadius: 3, opacity: 0 }}>
                                    <CardContent>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            Word of the Day
                                        </Typography>
                                        <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                                            be{bull}nev{bull}o{bull}lent
                                        </Typography>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            adjective
                                        </Typography>
                                        <Typography variant="body2" color="text.primary">
                                            well meaning and kindly.
                                            <br />
                                            {'"a benevolent smile"'}
                                        </Typography>
                                    </CardContent>
                                    <CardActions sx={{ justifyContent: 'center' }}>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                textTransform: 'none',
                                                ':hover': { bgcolor: '#f0f0f0', borderColor: '#1976d2' },
                                            }}
                                        >
                                            Learn More
                                        </Button>
                                    </CardActions>
                                </Card>

                            }

                        </Box>
                    </Container>

                </div>


                {/* recents */}
                <div className="tv-container-demo">

                    <Stack direction="row" spacing={2}>
                        <h2>Live</h2>
                        <YouTubeIcon fontSize="large" color="error" />
                    </Stack>
                    <br />
                    <Container sx={{ py: 4 }}>
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                                gridAutoRows: 'auto',
                                gap: { xs: 3, md: 4 }, // Responsive gap value
                                bgcolor: 'background.paper',
                                p: 3,
                                borderRadius: 2,
                                justifyContent: 'center',
                                alignItems: 'start',
                            }}
                        >
                            <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
                                <Box
                                    sx={{
                                        position: 'relative',
                                        paddingTop: '56.25%', // Maintain aspect ratio (16:9)
                                        overflow: 'hidden',
                                        borderRadius: '4px 4px 0 0',
                                    }}
                                >

                                    <iframe
                                        src="https://www.youtube.com/embed/sPSOQQi9XYQ?si=aAD98S9uhQdT6bT9"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin"
                                        allowFullScreen
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    />
                                </Box>
                                <CardActions sx={{ justifyContent: 'center', p: 2 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        href="https://www.youtube.com/embed/sPSOQQi9XYQ?si=aAD98S9uhQdT6bT9"
                                        target="_blank"
                                        sx={{
                                            textTransform: 'none',
                                            ':hover': { bgcolor: '#1976d2', color: 'white' }, // Hover effect
                                        }}
                                    >
                                        Watch Video on Youtube
                                    </Button>
                                </CardActions>
                            </Card>
                            <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
                                <Box
                                    sx={{
                                        position: 'relative',
                                        paddingTop: '56.25%', // Maintain aspect ratio (16:9)
                                        overflow: 'hidden',
                                        borderRadius: '4px 4px 0 0',
                                    }}
                                >
                                    <iframe
                                        src="https://www.youtube.com/embed/vgJRYKXXcEY?si=lhqECjROS_6Jl1Bf"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin"
                                        allowFullScreen
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    />
                                </Box>
                                <CardActions sx={{ justifyContent: 'center', p: 2 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        href="https://www.youtube.com/embed/vgJRYKXXcEY?si=lhqECjROS_6Jl1Bf"
                                        target="_blank"
                                        sx={{
                                            textTransform: 'none',
                                            ':hover': { bgcolor: '#1976d2', color: 'white' }, // Hover effect
                                        }}
                                    >
                                        Watch Video on Youtube
                                    </Button>
                                </CardActions>
                            </Card>

                        </Box>
                    </Container>

                </div>


                {/* shorts */}
                <div className="tv-container-demo">

                    <Stack direction="row" spacing={2}>
                        <h2>Shorts</h2>
                        <YouTubeIcon fontSize="large" color="error" />
                    </Stack>
                    <br />
                    <Container sx={{ py: 4 }}>
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                                gridAutoRows: 'auto',
                                gap: { xs: 3, md: 4 }, // Responsive gap value
                                bgcolor: 'background.paper',
                                p: 3,
                                borderRadius: 2,
                                justifyContent: 'center',
                                alignItems: 'start',
                            }}
                        >
                            <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
                                <Box
                                    sx={{
                                        position: 'relative',
                                        // paddingTop: '56.25%', // Maintain aspect ratio (16:9)
                                        overflow: 'hidden',
                                        borderRadius: '4px 4px 0 0',
                                    }}
                                >
                                    <iframe
                                        width={isMobile ? "" : "520"}
                                        height="315"
                                        src="https://www.youtube.com/embed/4n8ywuNFQJQ"
                                        title="YouTube Shorts video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen
                                    ></iframe>

                                </Box>
                                <CardActions sx={{ justifyContent: 'center', p: 2 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        href="https://www.youtube.com/shorts/4n8ywuNFQJQ"
                                        target="_blank"
                                        sx={{
                                            textTransform: 'none',
                                            ':hover': { bgcolor: '#1976d2', color: 'white' }, // Hover effect
                                        }}
                                    >
                                        Watch Video on Youtube
                                    </Button>
                                </CardActions>
                            </Card>

                            <Card sx={{ width: 300, boxShadow: 3, borderRadius: 3, opacity: 0 }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Word of the Day
                                    </Typography>
                                    <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                                        be{bull}nev{bull}o{bull}lent
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        adjective
                                    </Typography>
                                    <Typography variant="body2" color="text.primary">
                                        well meaning and kindly.
                                        <br />
                                        {'"a benevolent smile"'}
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'center' }}>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            textTransform: 'none',
                                            ':hover': { bgcolor: '#f0f0f0', borderColor: '#1976d2' },
                                        }}
                                    >
                                        Learn More
                                    </Button>
                                </CardActions>
                            </Card>



                        </Box>
                    </Container>

                </div>




            </div>

            <FooterComponent />

        </div>
    );
};

export default ShubadinamTv;